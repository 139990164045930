<template>
  <div class="page page-hp">
    <!--Section 1-->
    <div class="page-section section-first">
      <div class="page-section-col">
        <div class="logo-bandeau-white">
          <div class="img-block-brush">
            <img
              class="brush-logo"
              :src="this.$pathprod + 'images/home/harmony-hp-brush-1.png'"
              alt="brush-home"
              width="181"
              height="493"
            />
          </div>
          <div class="logo-block">
            <h1 style="display: none">{{ $t("nameSite") }}</h1>
            <img
              :src="this.$pathprod + 'images/Logo-harmony.svg'"
              alt="Logo-harmony"
              width="356"
              height="66"
            />
            <h2 class="logo-subtext">{{ $t("sloganSite") }}</h2>
          </div>
        </div>
      </div>

      <div class="scroll-bottom">
        <a
          href="#"
          aria-label="scroll to next"
          class="btn btn-white btn-scroll"
          v-on:click="scrollToNext"
        >
          <svg
            width="22"
            height="12"
            viewBox="0 0 22 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.799 1L10.8995 10.8995L1 1"
              stroke="#F3C678"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            width="22"
            height="12"
            viewBox="0 0 22 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.799 1L10.8995 10.8995L1 1"
              stroke="#F3C678"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
    </div>
    <!--/Section 1-->

    <!--Section chiffre key-->
    <div
      class="page-section section-key-number"
      :class="{ increment: increment }"
    >
      <div class="page-section-col">
        <div class="block-key-number">
          <div class="key-number-list">
            <div class="key-number-item">
              <p class="key-number">
                <!-- <span class="number-to-animate" data-number="13300">13 300</span>  -->
                <number
                  ref="number1"
                  :from="100"
                  :to="13000"
                  :format="theFormat"
                  animationPaused
                  :duration="1"
                  easing="Power1.easeOut"
                />
                m<span class="m2">2</span>
              </p>
              <p class="key-desc">{{ $t("chiffreKey")["0"] }}</p>
            </div>

            <div class="key-number-item">
              <p class="key-number">
                <!-- <span class="number-to-animate" data-number="1600">1 600</span>  -->
                <number
                  ref="number2"
                  :from="0"
                  :to="1600"
                  :format="theFormat"
                  animationPaused
                  :duration="1"
                  easing="Power1.easeOut"
                />
                m<span class="m2">2</span>
              </p>
              <p class="key-desc">{{ $t("chiffreKey")["1"] }}</p>
            </div>

            <div class="key-number-item">
              <p class="key-number">
                <!-- <span class="number-to-animate" data-number="1370">1 370</span> -->
                <number
                  ref="number3"
                  :from="0"
                  :to="1370"
                  :format="theFormat"
                  animationPaused
                  :duration="1"
                  easing="Power1.easeOut"
                />
                m<span class="m2">2</span>
              </p>
              <p class="key-desc">{{ $t("chiffreKey")["2"] }}</p>
            </div>

            <div class="key-number-item">
              <p class="key-number">
                <!-- <span class="number-to-animate" data-number="1173">1 173</span> -->
                <number
                  ref="number4"
                  :from="0"
                  :to="1173"
                  :format="theFormat"
                  animationPaused
                  :duration="1"
                  easing="Power1.easeOut"
                />
              </p>
              <p class="key-desc">{{ $t("chiffreKey")["3"] }}</p>
            </div>

            <div class="key-number-item">
              <p class="key-number">
                <!-- <span class="number-to-animate" data-number="250">250</span> -->
                <number
                  ref="number5"
                  :from="0"
                  :to="250"
                  :format="theFormat"
                  animationPaused
                  :duration="1"
                  easing="Power1.easeOut"
                />
                m<span class="m2">2</span>
              </p>
              <p class="key-desc">{{ $t("chiffreKey")["4"] }}</p>
            </div>

            <div class="key-number-item">
              <p class="key-number">
                <!-- <span class="number-to-animate" data-number="1100">1 100</span>  -->
                <number
                  ref="number6"
                  :from="0"
                  :to="1200"
                  :format="theFormat"
                  animationPaused
                  :duration="1"
                  easing="Power1.easeOut"
                />
                m2
              </p>
              <p class="key-desc">
                {{ $t("chiffreKey")["5"] }}
                <span class="key-desc-mini">{{ $t("chiffreKey")["6"] }}</span>
              </p>
            </div>

            <div class="key-number-item">
              <p class="key-number-text">
                <span>88</span> {{ $t("chiffreKey")["7"] }}
              </p>
              <p class="key-desc">{{ $t("chiffreKey")["8"] }}</p>
            </div>

            <div class="key-number-item">
              <!-- <p class="key-desc">{{ $t("chiffreKey")["9"] }}</p> -->
              <p class="key-number-text">
                <span class="yellow">265 m</span>
                <span class="m2 yellow">2</span>&nbsp;{{
                  $t("chiffreKey")["10"]
                }}
              </p>
            </div>
          </div>

          <lottie-animation
            class="brush-chiffre-key-mobile"
            ref="chiffre"
            :animationData="require('@/assets/lottie/brush-black.json')"
            :loop="false"
            :autoPlay="false"
            :speed="1"
          />
        </div>
        <div class="block-gold"></div>
      </div>
      <div class="page-section-col">
        <div class="img-section-block">
          <picture class="loadTemp">
            <source
              srcset=""
              :dta-srcset="
                this.$pathprod + 'images/home/harmony-hp-img-2-bis.webp'
              "
              type="image/webp"
            />
            <img
              src=""
              :dta-src="this.$pathprod + 'images/home/harmony-hp-img-2-bis.jpg'"
              srcset=""
              :dta-srcset="
                this.$pathprod + 'images/home/harmony-hp-img-2-bis.jpg'
              "
              alt="harmony-hp-img-2"
              class="bg-key-harmony"
              width="710"
              height="900"
            />
          </picture>
        </div>
      </div>
    </div>
    <!--Section chiffre key-->

    <!--Section 3-->
    <div class="page-section section-home-content">
      <div
        class="img-block-brush img-block-brush-desk"
        :class="{ showBrush: showBrush }"
      >
        <picture class="loadTemp">
          <source
            srcset=""
            :dta-srcset="this.$pathprod + 'images/home/harmony-hp-brush-3.webp'"
            type="image/webp"
          />
          <img
            src=""
            :dta-src="this.$pathprod + 'images/home/harmony-hp-brush-3.png'"
            srcset=""
            :dta-srcset="this.$pathprod + 'images/home/harmony-hp-brush-3.png'"
            alt="harmony-hp-brush-3"
            class="mask-brush"
            width="200"
            height="900"
          />
        </picture>
      </div>

      <div class="img-bg-mobile">
        <picture class="loadTemp">
          <source
            srcset=""
            :dta-srcset="
              this.$pathprod + 'images/home/harmony-hp-img-3-mobile.webp'
            "
            type="image/webp"
          />
          <img
            src=""
            :dta-src="
              this.$pathprod + 'images/home/harmony-hp-img-3-mobile.jpg'
            "
            srcset=""
            :dta-srcset="
              this.$pathprod + 'images/home/harmony-hp-img-3-mobile.jpg'
            "
            alt="harmony-hp-img-3-mobile"
            class="img-bg"
            width="375"
            height="667"
          />
        </picture>
        <div class="img-block-brush" :class="{ showBrush: showBrush }">
          <picture class="loadTemp">
            <source
              srcset=""
              :dta-srcset="
                this.$pathprod + 'images/home/harmony-hp-brush-3.webp'
              "
              type="image/webp"
            />
            <img
              src=""
              :dta-src="this.$pathprod + 'images/home/harmony-hp-brush-3.png'"
              srcset=""
              :dta-srcset="
                this.$pathprod + 'images/home/harmony-hp-brush-3.png'
              "
              alt="harmony-hp-brush-3"
              class="mask-brush"
              width="200"
              height="900"
            />
          </picture>
        </div>
      </div>

      <div class="page-section-col"></div>
      <div class="page-section-col">
        <div class="home-content">
          <div class="home-content-top">
            <div class="logo-block">
              <picture class="loadTemp">
                <source
                  srcset=""
                  :dta-srcset="
                    this.$pathprod + 'images/home/harmony-hp-brush.webp'
                  "
                  type="image/webp"
                />
                <img
                  src=""
                  :dta-src="this.$pathprod + 'images/home/harmony-hp-brush.png'"
                  srcset=""
                  :dta-srcset="
                    this.$pathprod + 'images/home/harmony-hp-brush.png'
                  "
                  alt="harmony-hp-brush"
                  class="brush"
                  width="52"
                  height="140"
                />
              </picture>
              <picture class="loadTemp">
                <source
                  srcset=""
                  :dta-srcset="
                    this.$pathprod + 'images/Logo-harmony-white.webp'
                  "
                  type="image/webp"
                />
                <img
                  src=""
                  :dta-src="this.$pathprod + 'images/Logo-harmony-white.svg'"
                  srcset=""
                  :dta-srcset="this.$pathprod + 'images/Logo-harmony-white.svg'"
                  alt="logo-harmony-white"
                  class="logo"
                  width="165"
                  height="31"
                />
              </picture>
            </div>
            <div class="home-top-right">
              <span class="home-text">{{ $t("sloganSite") }}</span>
            </div>
          </div>
          <div class="home-content-bottom">
            <p class="home-content-text">
              {{ $t("lastSectionHome")["0"] }}
            </p>

            <p class="home-content-text">
              {{ $t("lastSectionHome")["1"] }}
            </p>

            <p class="home-content-text">
              {{ $t("lastSectionHome")["2"] }}
            </p>

            <p class="home-content-text">
              {{ $t("lastSectionHome")["3"] }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--/Section 3-->
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  name: "Homemobile",
  components: {
    LottieAnimation,
  },
  props: {
    msg: String,
  },
  metaInfo() {
    return {
      title: "Harmony - Accueil",
      meta: [
        {
          name: "description",
          content:
            "Saint-ouen. Derrière ses lignes verticales et chaudes, HARMONY s’ouvre au mouvement. 13300 m2 de bureaux et services.",
        },
        { property: "og:title", content: "Harmony - Accueil" },
        { property: "og:site_name", content: "Harmony" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  data() {
    return {
      scroll: null,
      increment: false,
      showBrushK: false,
      showBrush: false,
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    theFormat(number) {
      return this.numberWithSpaces(number.toFixed(0));
    },

    playAnimation() {
      this.$refs.number1.play();
      this.$refs.number2.play();
      this.$refs.number3.play();
      this.$refs.number4.play();
      this.$refs.number5.play();
      this.$refs.number6.play();
    },

    addFormatNumber() {
      let item = document.querySelectorAll(".number-to-animate");
      for (let i = 0; i < item.length; i++) {
        item[i].innerText = this.numberWithSpaces(item[i].innerText);
      }
    },

    scrollToNext(e) {
      e.preventDefault();
      var container = this.$el.querySelector(".section-key-number");
      container.scrollTop = container.scrollHeight;
      container.scrollIntoView({ behavior: "smooth" });
    },

    /*Fonction executé pour le scroll*/
    handleScroll() {
      const el = document.querySelector(".section-key-number");
      const el2 = document.querySelector(".section-home-content");
      if (this.increment == false) {
        if (window.scrollY >= el.scrollTop - 200) {
          this.playAnimation();
          this.increment = true;
          this.beginAnimationBrushKeyNumber();
          this.appearLottieAnmi2();
        }
      }
      if (this.showBrush == false) {
        if (window.scrollY >= el2.offsetTop - 200) {
          this.beginAnimationBrushLastSection();
        }
      }
    },

    /*Fonction beginAnimationBrush pour la section 3*/
    beginAnimationBrushKeyNumber() {
      this.showBrushK = true;
    },

    /*Fonction beginAnimationBrush pour la section 3*/
    beginAnimationBrushLastSection() {
      this.showBrush = true;
    },

    appearLottieAnmi2() {
      this.$refs.chiffre.play();
    },
  },

  mounted() {
    /*Diffère tous les images*/
    setTimeout(
      function () {
        let loadImgSource = document.querySelectorAll(".loadTemp source");
        let loadImgImg = document.querySelectorAll(".loadTemp img");
        for (let i = 0; i < loadImgSource.length; i++) {
          loadImgSource[i].setAttribute(
            "srcset",
            loadImgSource[i].getAttribute("dta-srcset")
          );
          loadImgImg[i].setAttribute(
            "src",
            loadImgImg[i].getAttribute("dta-src")
          );
          loadImgImg[i].setAttribute(
            "srcset",
            loadImgImg[i].getAttribute("dta-srcset")
          );
        }
      }.bind(this),
      1200
    );
  },
};
</script>

<style scoped></style>
